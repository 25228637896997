.slick-next:before {
    color: rgba(4, 120, 87,1) !important;
}
.slick-prev:before {
    color: rgba(4, 120, 87,1) !important;
}
.w-auto-imp {
    width: auto !important;
}
.h-450-px {
    height: 450px;
}