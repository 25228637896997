.link::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  margin-top: 5px;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
}

.link:hover::before {
transform-origin: left;
transform: scaleX(1);
}